<template>
  <main class="layout--default">
    <NuxtLoadingIndicator color="#bf3bd9"/>
    <PageHeader :config="config" />
    <slot />
    <PageFooter :config="config" />
  </main>
</template>

<script setup>
const isPreview = useRuntimeConfig().public.CMS_ENV !== "production";

const story = await useAsyncStoryblok("data/config", {
  version: isPreview ? "draft" : "published",
  resolve_links: [],
});

const config = story.value.content;
</script>
